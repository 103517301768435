<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="550px" top="10vh" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm" label-width="120px">
                    <el-form-item label="任务id：" prop="taskId">
                        <span>{{m.task.name}}</span>
                    </el-form-item>
                    <el-form-item label="信息描述：" prop="title">
                        <el-input type="textarea" rows="2" placeholder="项目地点" v-model="m.title"></el-input>

                    </el-form-item>
                    <el-form-item label="验收标准：" prop="standard">
                        <el-input type="textarea" rows="2" placeholder="项目地点" v-model="m.standard"></el-input>

                    </el-form-item>
                    <el-form-item label="是否合格">
                        <input-enum enumName="taskTestStateEnum" v-model="m.state"></input-enum>
                    </el-form-item>
                    <el-form-item label="指派人：" prop="username">
                        <el-input v-model="m.username"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                taskId: [],
                title: [
                    {
                        min: 3,
                        max: 1000,
                        message: "长度在 3 到 1000 个字符",
                        trigger: "blur",
                    },
                ],
                standard: [],
                state: [],
                username: [],
            },
            fileList: [],
        };
    },
    methods: {
        open: function (data) {
            this.isShow = true;
            if (data.id) {
                this.title = "修改 开发任务测试";
                this.m = data;
            } else {
                this.m = {
                    taskId: data.task.id,
                    title: "",
                    standard: "",
                    state: 0,
                    username: 0,
                };
                this.m.task = data.task;
                this.title = "添加 开发任务测试";
            }
        },

        //提交开发任务测试信息
        ok: function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.sa.post("/taskTest/save", this.m).then((res) => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
    created() {},
};
</script>